.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.formWrapper {
  width: 500px;
}

.header {
  color: #fff;
  padding: 0 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.headerLeft {
  height: inherit;
  line-height: 50px;
  font-size: 20px;
  font-weight: 700;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}