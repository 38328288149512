@import '~antd/dist/antd.less';

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

input, textarea {
  font-size: initial;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-break: normal; /* anywhere */
}

.nowrap {
  word-wrap: nowrap;
  white-space: nowrap;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#deactivatedTable .ant-table-small .ant-table-thead > tr > th {
  background-color: #FEC8C8;
}

#deletedTable .ant-table-small .ant-table-thead > tr > th {
  background-color: #000;
  color: white;
}
@primary-color: #1890ff;