.box {
  border: 1px solid #aaa;
  padding: 15px 15px 0 15px;
  margin: 10px 0;
}

.btn {
  display: flex;
  justify-content: flex-end;
}

.rowOne {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}


.mr {
  margin-right: 20px;
}

.mrs {
  margin-right: 10px;
}

.mrl {
  margin-right: 110px;
}

.formItem {
  margin-bottom: 0px;
}

.flexOne {
  flex: 1;
}