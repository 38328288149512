
.formWrapper {
  width: 500px;
  padding: 25px;
  background-color: #fff;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

}

.title {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-bottom: 50px;
}

.message {
  text-align: center;
}