.dot {
  width: 10px;
  height: 10px;
  margin-right: 7px;
  border-radius: 50%;
}

.mr {
  margin-right: 10px;
}

.txt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
  overflow-wrap: break-word;
}

.icon {
  margin-left: 5px;
  cursor: pointer;
}