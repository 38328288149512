body {
  overscroll-behavior: none;
}

.page {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

.header {
  color: #fff;
  padding: 0 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.headerLeft {
  height: inherit;
  line-height: 50px;
  font-size: 20px;
  font-weight: 700;
}

.headerRight {
  height: inherit;
  line-height: 50px;
}

.menu {
  padding: none;
  margin: none;
}

.avatar {
  margin-right: 10px;
}

.content {
  background-color: white;
  padding: 0 20px;
}

