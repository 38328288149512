.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  margin-top: 20px;
}

